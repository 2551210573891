import { useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Layout from '../src/components//Layout'
import { Provider } from 'react-redux'
import store from './store'
import Home from './pages/Home'
import Login from './pages/Login'
import Playersdata from './pages/Playersdata'
import Tournaments from './pages/Tournaments'
import Tournament from './pages/Tournament'
import './App.css'
import AuthenticatedRoute from './components/AuthenticatedRoute'
import NotFoundPage from './pages/NotFoundPage'
import { loadUser } from './actions/user'

const App = () => {
    useEffect(() => {
        store.dispatch(loadUser())
    }, [])

    return (
        <Provider store={store}>
            <BrowserRouter>
                <Layout>
                    <Routes>
                        <Route path='*' element={<NotFoundPage />} />
                        <Route path ="/" element ={
                            <AuthenticatedRoute>
                                <Home/>
                            </AuthenticatedRoute>
                        } />
                        <Route path="login" element={<Login/>}/>
                        <Route path="playersdata" element={
                            <AuthenticatedRoute>
                                <Playersdata/>
                            </AuthenticatedRoute>
                        } />
                        <Route path="tournaments" element={
                            <AuthenticatedRoute>
                                <Tournaments/>
                            </AuthenticatedRoute>
                        } />
                            <Route path="tournament/:tournamentid" element={
                            <AuthenticatedRoute>
                                <Tournament/>
                            </AuthenticatedRoute>
                        } />
                    </Routes>
                </Layout>
            </BrowserRouter>
        </Provider>
    )
}

export default App