import { Navigate } from 'react-router-dom'
import { connect } from 'react-redux'

const AuthenticatedRoute = ({children, user}) => {
    if (user.isAuthenticated) {
        return children
    } else {
        return <Navigate to='/login' />
    }
}

const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps)(AuthenticatedRoute)