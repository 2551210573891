import { useState, useEffect} from "react"
import { connect } from 'react-redux'
import axios from 'axios'
import KuroLink from '../components/KuroLink'
import  '../styles/tournaments.css'
import  '../styles/table.css'

const Tournaments = ({user: {token}}) => {

    const [tournaments, settournaments] = useState(null)

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'rebellion/tournaments?foradmin=true', config).then(res => {
            settournaments(res.data)
        })
    }, [token])

  return (
    <div className="min-height">
        <div className='mx-width'>
            <div className='title_container'>
                <h1>Tournaments</h1>
            </div>
                {tournaments !== null &&
                <div className="tour_container">
                    <table className="border even_odd" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <th>Tournament ID</th>
                            <th>Tournament Name</th>
                            <th>Game</th>
                            <th>Venue</th>
                            <th>Date</th>
                            {tournaments.map((tour, i) => 
                            <tr key={i}>
                                <td><KuroLink to={"/tournament/" + tour.tournamentid }>{tour.tournamentid}</KuroLink></td>
                                <td>{tour.name}</td>
                                <td>{tour.game}</td>
                                <td>{tour.venue}</td>
                                <td>{tour.tour_date}</td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                }
        </div>
    </div>
  )
}
const mapStateToProps = state => ({
    user: state.user
})
export default connect(mapStateToProps)(Tournaments)