// Kuro User
export const USER_LOADED = 'USER_LOADED'
export const USER_LOADING = 'USER_LOADING'
export const AUTH_FAIL = 'AUTH_FAIL'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const OTP_FAIL = 'OTP_FAIL'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const LOGIN_MESSAGE = 'LOGIN_MESSAGE'
export const REDIRECT_TO = 'REDIRECT_TO'
