import Helmet from 'react-helmet'
import Header from './Header'
import Footer from './Footer'

const Layout = ({title, description, keywords, children}) => {
    return (
        <div className='reb_wrapper'>
            <Helmet>
            <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="locale" content="IN" />
                <meta name="language" content="en" />
                <meta name="country" content="IN" />
                <link rel="icon" href="logo.png" />
                <meta name="theme-color" content="#000000" />
                <meta property="og:type" content="website" />
                <title>{title}</title>
                <meta name="title" content={title} />
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content="https://rebellionesports.gg/static/media/logo.jpg" />
                <meta property="og:url" content="https://rebellionesports.gg" />
                <link rel="canonical" href="https://rebellionesports.gg" />
            </Helmet>
            <Header/>
                {children}
            <Footer/>
        </div>
    )
}

export default Layout