import axios from 'axios'
import { USER_LOADED, USER_LOADING, AUTH_FAIL, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT_SUCCESS, OTP_FAIL, LOGIN_MESSAGE, REDIRECT_TO } from './types'
import store from '../store'

// Headers
const config = {
    headers: {
        'Content-Type': 'application/json',
    },
}

export const updateLoginMsg = (loginMsg) => (dispatch) => {
    dispatch({
        type: LOGIN_MESSAGE,
        msg: loginMsg
    })
}

// CHECK TOKEN & LOAD USER
export const loadUser = () => (dispatch) => {
    // User Loading
    dispatch({ type: USER_LOADING })
    let token = localStorage.getItem('token')
    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        },
    }
    axios
        .get(process.env.REACT_APP_KC_API_URL + 'kuro/user', config)
        .then((res) => {
            dispatch({
                type: USER_LOADED,
                payload: res.data[0],
            })
        })
        .catch((err) => {
            dispatch({
                type: AUTH_FAIL,
            })
        })
}
  
// LOGIN USER WITH PASSWORD
export const pwdLogin = (username, password) => (dispatch) => {
    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    }
  
    // Request Body
    const body = JSON.stringify({ username, password })

    axios
        .post(process.env.REACT_APP_KC_API_URL + 'auth/staff', body, config)
        .then((res) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data,
            })
        })
        .catch((err) => {
            if (err.response && err.response.status === 400) {
                dispatch({
                    type: LOGIN_MESSAGE,
                    msg: "Incorrect Login Credentials"
                })
            }
            dispatch({
                type: LOGIN_FAIL,
            })
        })
}

// GET OTP FOR Existing User
export const getOtp = (userid) => (dispatch) => {
    const newuser = "false"

    // Request Body
    const body = JSON.stringify({ newuser, userid })

    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    }

    axios
        .post(process.env.REACT_APP_KC_API_URL + 'verify', body, config)
        .then((res) => {
            dispatch({
                type: LOGIN_MESSAGE,
                msg: "otp sent"
            })
        })
        .catch((err) => {
            if (err.response && err.response.status === 400) {
                dispatch({
                    type: LOGIN_MESSAGE,
                    msg: err.response.data.msg
                })
            }
            dispatch({
                type: OTP_FAIL,
            })
        })
}

// LOGIN USER WITH OTP
export const otpLogin = (phone, otp) => (dispatch) => {
    // Request Body
    const body = JSON.stringify({ phone, otp })

    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    }

    axios
        .post(process.env.REACT_APP_KC_API_URL + 'auth/login', body, config)
        .then((res) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data,
            })
        })
        .catch((err) => {
            if (err.response && err.response.status === 400) {
                dispatch({
                    type: LOGIN_MESSAGE,
                    msg: err.response.data.msg
                })
            }
            dispatch({
                type: LOGIN_FAIL,
            })
        })
}

// LOGOUT USER
export const logout = () => (dispatch) => {
    axios
        .post(process.env.REACT_APP_KC_API_URL + 'auth/logout', null, tokenConfig())
        .then((res) => {
            dispatch({ type: 'CLEAR_LEADS' })
            dispatch({
                type: LOGOUT_SUCCESS,
            })
            dispatch({
                type: REDIRECT_TO,
                url: "/login"
            })
        })
        .catch((err) => {
        })
}
  
// Setup config with token - helper function
export const tokenConfig = () => {
    // Get token from state
    const token = store.getState().user.token
  
    // If token, add to headers config
    if (token) {
        config.headers['Authorization'] = `Token ${token}`
    }

    return config
}