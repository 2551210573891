import KuroLink from '../components/KuroLink'
import '../styles/home.css'

const Home = () => {

    return (
        <div className='home'>
            <div className="banner_text home_banner mx-width">
                <h3>Rebellion eSports Tournaments</h3>
                <div className='banner_btns'>
                    <KuroLink classList="txt-link" to ="/playersdata"><button className="banner_btn">Player/Team</button></KuroLink>
                    <KuroLink classList="txt-link" to ="/tournaments"><button className="banner_btn">Tournament</button></KuroLink>
                </div>
            </div>
        </div>
    )
}

export default Home