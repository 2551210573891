import React from 'react';
import Img404 from '../assets/img/page_404.svg'
import '../styles/notfound.css'

const NotFoundPage = () => {
    return (
        <div className="not-found mx-width txt-grey">
            <img title="Kuro Gaming" alt="Kuro Gaming" src={Img404} />
            <h2>Oops! Something went wrong</h2>
        </div>
    )
}

export default NotFoundPage;