import { useState, useEffect} from "react"
import { useParams} from 'react-router-dom'
import { connect } from 'react-redux'
import axios from 'axios'

const Tournament = ({user: {token}}) => {

    const { tournamentid} = useParams()
    const [tourdata, settourdata] = useState(null)

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'rebellion/tourneyregister?tournamentid=' + tournamentid, config).then(res => {
            settourdata(res.data[0])
        })
    }, [token])


    return (
        <div className="min-height">
            <div className='mx-width'>
                <p>&nbsp;</p>
                {tourdata !== null &&
                <div className="tour_container">
                    <table className="border even_odd tourtable" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <th>Tournament ID</th>
                            <th>Tournament Name</th>
                            <th>Game</th>
                            <th>Venue</th>
                            <th>Date</th>
                            <tr>
                                <td>{tourdata.tournamentid}</td>
                                <td>{tourdata.name}</td>
                                <td>{tourdata.game}</td>
                                <td>{tourdata.venue}</td>
                                <td>{tourdata.tour_date}</td>
                            </tr>
                        </tbody>
                    </table>
                    {tourdata.registrations.map((reg, i) =>
                    reg.playertype === "Player" ? (
                    <table className="border even_odd playertable" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <th>Name</th>
                                <th>RIOT ID</th>
                                <th>Rank</th>
                                <th>Mobile</th>
                            </tr>
                            {reg.players.map((player, i) =>
                            <tr>
                                <td>{player.name}</td>
                                <td>{player.riotid}</td>
                                <td>{player.rank}</td>
                                <td>{player.mobile}</td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                    ) : (
                        <table className="border even_odd teamtable" cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <th>Player Type</th>
                                    <th>Name</th>
                                    <th>RIOT ID</th>
                                    <th>Rank</th>
                                    <th>Mobile</th>
                                </tr>
                                {reg.players.map((player, i) =>
                                <tr>
                                    <td>{player.type}</td>
                                    <td>{player.name}</td>
                                    <td>{player.riotid}</td>
                                    <td>{player.rank}</td>
                                    <td>{player.mobile}</td>
                                </tr>
                                )}
                            </tbody>
                        </table>
                    )
                    )}
                </div>
                }
                <p>&nbsp;</p>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps) (Tournament)