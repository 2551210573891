import { connect } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { logout } from '../actions/user'
import '../styles/header.css'

const Header = ({user: {isAuthenticated, userDetails}, logout}) => {

    const navigateTo = useNavigate()

    const hbToggleHandler = () => {
        document.getElementById("header").classList.toggle("hb-open")
        document.body.classList.toggle("hb-open")
    }

    const hbMaskHandler = () => {
        document.getElementById("header").classList.remove("hb-open")
        document.body.classList.remove("hb-open")
    }

    const logoutHandler = () => {
        logout()
        navigateTo('login')
    }

    return (
        <header className="header" id="header">
            <div className="blowout row">
                <div className="hb_menu" onClick={hbToggleHandler}>
                    <span></span>
                </div>
                <div className="hb_mask" onClick={hbMaskHandler}></div>
                <div className="cntleft">
                    <ul className="sitenav">
                    {isAuthenticated &&
                        <div className="item account mob">
                            <div className="med">
                                <svg fill="#c41c91" width="30" height="30" viewBox="0 0 24 24"><path d="M12 2c2.757 0 5 2.243 5 5.001 0 2.756-2.243 5-5 5s-5-2.244-5-5c0-2.758 2.243-5.001 5-5.001zm0-2c-3.866 0-7 3.134-7 7.001 0 3.865 3.134 7 7 7s7-3.135 7-7c0-3.867-3.134-7.001-7-7.001zm6.369 13.353c-.497.498-1.057.931-1.658 1.302 2.872 1.874 4.378 5.083 4.972 7.346h-19.387c.572-2.29 2.058-5.503 4.973-7.358-.603-.374-1.162-.811-1.658-1.312-4.258 3.072-5.611 8.506-5.611 10.669h24c0-2.142-1.44-7.557-5.631-10.647z"></path></svg>
                            </div>
                            <div className="cnt">
                                <p>{userDetails.name}</p>
                            </div>
                            <div className='logout'>
                                <p onClick={logoutHandler}>Logout<svg width="30px" height="25px" viewBox="0 0 24 24" fill="none"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 3H7a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h8m4-9-4-4m4 4-4 4m4-4H9"/></svg></p>
                            </div>
                        </div>
                        }
                        <div className="mob">
                            <a href='https://rebellionesports.gg/'><p className="btn signbtn">Go To Main Site</p></a>
                        </div>
                    </ul>
                </div>
                <div className="cntcenter">
                    <div className="logo">
                        <a href='https://rem.rebellionesports.gg'>
                            <img className="imglogo" alt="Rebellion Esports" src='logo.png' />
                        </a>
                        <a href='https://rem.rebellionesports.gg'><img className= "textlogo" alt="Rebellion Esports" src='text-logo.png' /></a>
                        
                    </div>
                </div>
                <div className="cntright">
                    <a href='https://rebellionesports.gg' target = "blank"><p className="btn signbtn">Go To Main Site</p></a>
                </div>
            </div>
        </header>
    )
}
const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps, {logout})(Header)