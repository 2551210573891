import { useState, useEffect} from "react"
import { connect } from 'react-redux'
import axios from 'axios'
import KuroLink from '../components/KuroLink'

const Playersdata = ({user: {token}}) => {

    const [playerdata, setplayerdata] = useState(null)

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        // axios(process.env.REACT_APP_KC_API_URL + 'rebellion/', config).then(res => {
        //     setplayerdata(res.data)
        // })
    }, [token])

  return (
    <div className="min-height">
        <div className="mx-width">
            <div className='title_container'>
                <h1>Solo Player Details</h1>
            </div>
            <div className="tour_container">
                <table className="border even_odd" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <th>Player ID</th>
                        <th>Name</th>
                        <th>RIOT ID</th>
                        <th>Rank</th>
                        <th>Mobile</th>
                        <tr >
                            <td><KuroLink to={"/tournament/" }>{}</KuroLink></td>
                            <td>{}</td>
                            <td>{}</td>
                            <td>{}</td>
                            <td>{}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='title_container'>
                <h1>Team Details</h1>
            </div>
            <div className="tour_container">
                <table className="border even_odd" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <th>Team ID</th>
                        <th>Team Name</th>
                        <th>Coach Name</th>
                        <th>Type</th>
                        <th>Name</th>
                        <th>RIOT ID</th>
                        <th>Rank</th>
                        <th>Mobile</th>
                        <tr >
                            <td><KuroLink to={"/tournament/" }>{}</KuroLink></td>
                            <td>{}</td>
                            <td>{}</td>
                            <td>{}</td>
                            <td>{}</td>
                            <td>{}</td>
                            <td>{}</td>
                            <td>{}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  )
}
const mapStateToProps = state => ({
    user: state.user
})
export default connect(mapStateToProps)(Playersdata)